body {
  margin: 0;
  scroll-behavior: smooth;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
.css-1y1f37v-MuiFormControl-root-MuiTextField-root {
  background-color: #f5f5f5 !important;
  color: black !important;
}
.icon-class-name {
  display: none !important  ;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
::-webkit-scrollbar {
  width: 12px; /* Adjust the width as needed */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Track color */
}

::-webkit-scrollbar-thumb {
  background-color: #0000005d; /* Thumb color */
  border-radius: 10px; /* Round the thumb */
}

.band {
  width: 90%;
  max-width: 1240px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 20px;
}
@media (min-width: 30em) {
  .band {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 60em) {
  .band {
    grid-template-columns: repeat(4, 1fr);
  }
}

.card {
  background: white;
  text-decoration: none;
  color: #444;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
  top: 0;
  transition: all 0.1s ease-in;
}
.card:hover {
  top: -2px;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
}
.card article {
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card h1 {
  font-size: 20px;
  margin: 0;
  color: #333;
}
.card p {
  flex: 1;
  line-height: 1.4;
}
.card span {
  font-size: 12px;
  font-weight: bold;
  color: #999;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin: 2em 0 0 0;
}
.card .thumb {
  padding-bottom: 60%;
  background-size: cover;
  background-position: center center;
}

@media (min-width: 60em) {
  .item-1 {
    grid-column: 1 / span 1;
  }
  .item-1 h1 {
    font-size: 24px;
  }
}
