@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Castoro:ital@0;1&family=Exo:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Exo:ital,wght@0,100..900;1,100..900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: rgb(0, 0, 0);
  height: 200vh;
  font-family: "Castoro", serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.social-media-nav {
  position: fixed;
  top: 15vh;
  left: 0;
  z-index: 10000;
}
.img_wechat {
  width: 30rem;
}

.social-media-nav ul {
  display: block;
  list-style-type: none;
  padding-left: 0;
  text-align: center;
}

.side-social {
  width: 90px;
  height: 80px;
  line-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 40px;
  color: #fff;
  box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.7);
  transition: 0.2s;
}

.side-social:hover {
  width: 100px;
  transition: 0.5s;
}

.facebook {
  background: #3b5998;
  border-radius: 0 30% 0 0;
}

.wechat {
  background: #09b83e;
}

.eventbrite {
  background: #ff8000;
}
.css-bhp9pd-MuiPaper-root-MuiCard-root {
  background-color: #000000 !important;
}
.instagram {
  background: #fccc63;
  border-radius: 0 0 30% 0;
}

.linkedin {
  background: #0e76a8;
  border-radius: 0 0 30% 0;
}
.spotify {
  background: #ffffff;
}
.facebook {
  background: #3b5998;
  border-radius: 0 30% 0 0;
  width: 89px; /* Adjust width for smaller screens */
  height: 60px; /* Adjust height for smaller screens */
}

/* Adjust size and positioning for smaller screens */
@media (max-width: 768px) {
  .facebook {
    width: 60px;
    height: 60px;
    border-radius: 0;
  }
  .social-media-nav {
    position: fixed;
    top: 38vh;
    left: 0;
    z-index: 10000;
  }
  .wechat {
    width: 60px;
    height: 50px;
    border-radius: 0; /* Remove border radius for square appearance */
  }
  .eventbrite {
    width: 60px;
    height: 50px;
    border-radius: 0; /* Remove border radius for square appearance */
  }
  .instagram {
    width: 60px;
    height: 50px;
    border-radius: 0; /* Remove border radius for square appearance */
  }
  .linkedin {
    width: 60px;
    height: 50px;
    border-radius: 0; /* Remove border radius for square appearance */
  }
  .spotify {
    width: 60px;
    height: 50px;
    border-radius: 0; /* Remove border radius for square appearance */
  }
  .side-social {
    width: 70px;
    height: 60px;
    font-size: 30px;
    line-height: 40px;
  }
  .side-social:hover {
    width: 70px;
    transition: 0.3s;
  }
}
.css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input {
  color: rgb(0, 0, 0) !important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
}
.css-15kq27i {
  color: white !important;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: pink;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: pink;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: pink;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: pink;
}
.css-u36398 {
  color: black !important;
}

.bricks {
  padding-top: 4.2rem;
}

#bricks .masonry {
  max-width: 1440px;
}

#bricks .pagination {
  margin-top: 6rem;
}

#bricks.with-top-sep {
  position: relative;
}

#bricks.with-top-sep::before {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 0;
  margin-left: -100px;
  width: 200px;
  height: 1px;
  background-color: #d1d1d1;
}

.bricks-wrapper:before,
.bricks-wrapper:after {
  content: "";
  display: table;
}

.bricks-wrapper:after {
  clear: both;
}

.bricks-wrapper .grid-sizer,
.bricks-wrapper .brick {
  width: 25%;
}

.bricks-wrapper .brick {
  float: left;
  padding: 0 20px;
}

.bricks-wrapper .featured-grid {
  width: 50%;
}

.bricks-wrapper .featured-grid .entry-content {
  width: 100%;
  background: #151515;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns of equal size */
  grid-template-rows: auto auto; /* Two rows that auto-adjust their height */
  gap: 10px; /* Space between grid items */
  margin-top: 1.3rem;
}

.home-container {
}

.featured {
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.main-description {
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  color: white;
  font-size: 3.8rem;
  font-weight: 600;
}

.featured img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(390px, 2fr));
  grid-gap: 20px;
  padding: 20px;
}

.item {
  border-radius: 8px; /* Optional rounded corners */
  overflow: hidden; /* Ensures nothing spills outside the item */
}

/* For larger items spanning 2 columns */
.item.large {
  grid-column: span 1;
}

/* For taller items spanning 2 rows */
.item.tall {
  grid-row: span 3;
}
